#kubkozaCreatorModal .header {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

#kubkozaCreatorModal .footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  margin: 0;
}

#kubkozaCreatorModal .main-wrapper {
  padding: 56px 8px !important;
}

#kubkozaCreatorModal .preview-wrapper {
  background: rgb(255, 255, 255, 0.75);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0.8) 90%, rgba(255, 255, 255, 0) 100%);
  /* pointer-events: none; */
  z-index: 1021;
}

#kubkozaCreatorModal .preview {
  position: relative;
  height: 100%;
}

#kubkozaCreatorModal .preview img {
  max-width: 100%;
  height: auto;
  position: absolute;
}

#kubkozaCreatorModal .loader {
  z-index: -9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: backdrop-filter 300ms ease-in-out;
  backdrop-filter: blur(12px) opacity(0);
}

#kubkozaCreatorModal .loader.show {
  z-index: 9999;
  transition: backdrop-filter 300ms  ease-in-out;
  backdrop-filter: blur(12px) opacity(1);
}

#kubkozaCreatorModal .item-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

#kubkozaCreatorModal .item-wrapper:hover {
  cursor: pointer;
}

#kubkozaCreatorModal .item {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 3px solid white;
  background-position: center;
  background-size: cover;
  text-align: center;
  background-repeat: no-repeat;
  line-height: 94px;
  text-indent: -1000em;
}

#kubkozaCreatorModal .item.contain {
  background-size: contain;
}

#kubkozaCreatorModal .item.large {
  width: 120px;
  height: 120px;
}

#kubkozaCreatorModal .item-wrapper:hover .item, #kubkozaCreatorModal .item-wrapper.active:hover .item {
  box-shadow: 0 0 10px orange;
  border-color: white;
}

#kubkozaCreatorModal .item-wrapper.active .item {
  box-shadow: 0 0 10px orange;
  border-color: orange;
}

#kubkozaCreatorModal canvas {
  width: 100%;
  height: auto;
}

#kubkozaCreatorModal .accordion-header {
  z-index: 1022;
}

#kubkozaCreatorModal .accordion-button {
  color: inherit !important;
  background-color: inherit !important;
  box-shadow: inherit !important;
}

#kubkozaCreatorModal .accordion-button:focus {
  border-color: inherit;
  outline: 0;
  box-shadow: inherit;
}

#kubkozaCreatorModal .logo {
  height: 38px;
  width: auto;
}

#kubkozaCreatorModal .modal-body {
  padding: 0 !important;
}

#kubkozaCreatorModal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  &.show {
    display: block;
  }

  &.modal {
    background-color: white;
    z-index: 99999;
    overflow-x: hidden;
  }

  .btn-close {
    background-color: transparent;
  }

  .modal-dialog {
    width: auto;
    margin: 0;
  }

  .navbar {
    margin-bottom: 0;
    border-radius: 0;
  }

  .modal-content {
    border-radius: 0;
    box-shadow: none;
  }

  .footer {
    background-color: #f8f8f8;
    padding: 5px 0;
  }

  .justify-content-between {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &::before, &::after {
      content: initial;
    }
  }

  .preview-wrapper {
    position: sticky;
    top: 0;
  }

  .row-cols-auto {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .col {
      margin-left: 4px;
    }
  }

  .accordion {
    border: 1px solid #f8f8f8;
    border-top: 0;
    border-radius: 4px;
    margin-bottom: 16px;

    .accordion-item {
      .accordion-header  {
        margin: 0;
        border: 1px solid #f8f8f8;
        background: #f8f8f8;
        border-left: 0;
        border-right: 0;
        font-size: 1.25em;
        padding: 4px 8px;
        font-weight: bold;

        button {
          border: 0;
          background-color: transparent !important;
          width: 100%;
          text-align: left;
          margin: 0;
          padding: 0 4px;
          font-size: 1.1em;
        }
      }
    
      .accordion-collapse {
        display: none;
        &.show {
          display: block;
        }

        .accordion-body {
          padding: 8px;
        }
      }
    }
  }

  .my-row {
    display: block !important;
  }

  .addtocart {
    padding: 0 16px;
  }

  .addtocart-alt {
    margin-right: 4px;
    background-color: #e5e5e5;
  }

  .add-co-cart-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  @media (max-width: 767px) {
    .cta-buttons.justify-content-between {
      display: block;
    }
  }
}

.modal-body-overflow {
  overflow: hidden;
}